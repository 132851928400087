import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw from 'twin.macro'
import CircleImage from '@/components/atoms/util/CircleImage'
import SectionStyle from '../atoms/util/SectionStyle'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'
import Wrapper from '@/components/atoms/util/Wrapper'
import Container from '../atoms/flex/Container'
import Headline from '../atoms/util/Headline'
import Contact from '../organisms/Contact'
import Works from '../organisms/Works'

const BiographyTemplate: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Query {
      allFile(filter: { name: { eq: "profile" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `).allFile.edges[0].node.childImageSharp.gatsbyImageData
  return (
    <>
      <section css={SectionStyle(tw`lg:mt-0 mt-4 pt-24`)}>
        <Wrapper>
          <Container>
            <CircleImage image={data} alt="顔写真" />
            <div>
              <h2 tw="lg:text-3xl text-2xl lg:mt-0 mt-4 tracking-wider">
                田中 雄大(Tanaka Takehiro)
              </h2>
              <p css={ParagraphStyle(tw`mt-2`)}>
                🎂 1996/02/27
                <br />
                💼 合同会社TamaT CEO / Webエンジニア
                <br />
                🏠 神奈川県横浜市
                <br />
                🎮 筋トレ / サウナ / 自転車 / 音楽
              </p>
            </div>
          </Container>
        </Wrapper>
      </section>
      <section css={SectionStyle}>
        <Wrapper>
          <Headline>Career</Headline>
          <div tw="container lg:pt-12 pt-6">
            <div tw="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
              {/* <!-- left --> */}
              <div tw="flex flex-row-reverse md:contents">
                <div tw="bg-blue-500 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2017/07
                  </h3>
                  <p tw="leading-snug text-justify tracking-wider">
                    某メガベンチャー企業で企画職としてインターン入社
                    <br />
                    学生向けのイベント施策の企画を担当
                  </p>
                </div>
                <div tw="col-start-5 col-end-6 md:mx-auto relative mr-10">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
              </div>
              {/* <!-- right --> */}
              <div tw="flex md:contents">
                <div tw="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
                <div tw="bg-blue-500 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2018/07
                  </h3>
                  <p tw="leading-snug tracking-wider text-justify">
                    早稲田大学を休学
                    <br />
                    ベンチャー企業にてエンジニアインターンとして参画。React.js/firebaseでのメディア開発。
                    <br />
                    メガベンチャーで企画職から、エンジニア職に異動(Unity / C# /
                    javascript / PHP)
                  </p>
                </div>
              </div>
              {/* <!-- left --> */}
              <div tw="flex flex-row-reverse md:contents">
                <div tw="bg-blue-500 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2019/04
                  </h3>
                  <p tw="leading-snug tracking-wider text-justify">
                    早稲田大学復学
                    <br />
                    学業の傍ら、シード期のベンチャーにて業務委託契約で開発に従事。(TypeScript
                    / Next.js / Express/ Docker / MySQL)
                  </p>
                </div>
                <div tw="col-start-5 col-end-6 md:mx-auto relative mr-10">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
              </div>
              {/* <!-- left --> */}
              <div tw="flex flex-row-reverse md:contents">
                <div tw="bg-blue-500 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2019/09
                  </h3>
                  <p tw="leading-snug tracking-wider text-justify">
                    個人事業でwebサイト制作事業開始。
                    <br />
                    コーダー業務、Wordpressでのコーポレートサイトの制作などを主に行う。
                  </p>
                </div>
                <div tw="col-start-5 col-end-6 md:mx-auto relative mr-10">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
              </div>
              {/* <!-- right --> */}
              <div tw="flex md:contents">
                <div tw="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
                <div tw="bg-blue-500 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2020/04
                  </h3>
                  <p tw="leading-snug tracking-wider text-justify">
                    早稲田大学政治経済学部を卒業。
                    <br />
                    <a
                      href="https://tamat.jp"
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="underline"
                    >
                      合同会社TamaT
                    </a>
                    を設立し、アプリ開発、webサイトの制作、RPAツールの開発などを行う。
                  </p>
                </div>
              </div>
              {/* <!-- right --> */}
              <div tw="flex md:contents">
                <div tw="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                  <div tw="h-full w-6 flex items-center justify-center">
                    <div tw="h-full w-1 bg-blue-800 pointer-events-none"></div>
                  </div>
                  <div tw="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-blue-500 shadow"></div>
                </div>
                <div tw="bg-blue-500 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                  <h3 tw="font-semibold text-lg mb-1 tracking-wider">
                    2021/07
                  </h3>
                  <p tw="leading-snug tracking-wider text-justify">
                    地域のお店と人を繋ぐプラットフォーム
                    <a
                      href="https://localplan.app"
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="underline"
                    >
                      LocalPlan
                    </a>
                    をリリース。
                    <br />
                    たまプラーザを拠点とする、
                    <a
                      href="https://tamaplaza.localplan.app"
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="underline"
                    >
                      TamaPlan
                    </a>
                    からサービス提供開始。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
        <Works />
      </section>
      <Contact />
    </>
  )
}

export default BiographyTemplate
