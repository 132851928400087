import React from 'react'
import 'twin.macro'
import Layout from '@/shared/globalStyle'
import BiographyTemplate from '@/components/templates/BiographyTemplate'
import SEO from '@/shared/seo'
import profile from '../../static/images/profile.jpeg'

const Biography: React.FC = () => {
  return (
    <Layout>
      <SEO title="BIOGRAPHY" image={profile} lang="ja" />
      <BiographyTemplate />
    </Layout>
  )
}

export default Biography
